import { graphql } from 'gatsby'
import React from "react" 
import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image" 
import hamer from "../assets/icons/hamer.png"
import calendar from "../assets/icons/calendar.png"
import health from "../assets/icons/health.png"
import joint from "../assets/icons/joint.png"
import umbrella from "../assets/icons/joint.png"
import drop from "../assets/icons/drop.png"
import sun from "../assets/icons/sun.png"
import leaf from "../assets/icons/leaf.jpg"
import stoneItaliana from "../assets/img/solid-surface/supliers/stone-italiana.jpg"
import basSurface from "../assets/img/solid-surface/supliers/bas-surface.jpg"
import rehau from "../assets/img/solid-surface/supliers/rehau.jpg"


const SolidSurfacePage = ({data}) => {
  const g = data.g.edges.map(({ node }) => node.childImageSharp)
  const thermog1 = data.Thermog1.edges.map(({ node }) => node.childImageSharp)
  const thermog2 = data.Thermog2.edges.map(({ node }) => node.childImageSharp)
  const lightboxOptions = {
    wrapperClassName:'gal',
    imageLoadErrorMessage: 'Impossible de charger cette image',
    nextLabel: 'Image suivante',
    prevLabel: 'Image précédente',
    zoomInLabel: 'Zoomer',
    zoomOutLabel: 'Dézoomer',
    closeLabel: 'Fermer',
  }
  return(
  <Layout>
    <SEO title="Solid Surface" />
    <div class="w-full ss-bg">
      <div class="grid grid-cols-1 mt-8 md:mx-40 mx-3">
        <div class="text-white text-xl text-center font-normal leading-8 md:my-32 my-10">
          <p class="">Le matériau Solid Surface offre d’infinies possibilités de création et une résistance exceptionnelle tant pour le résidentiel que pour les environnements professionnels, de l’hôtel à l’hôpital, de l’aménagement de boutique au nautisme. Dans un équilibre d’esthétisme et de performance, il se prêt à l’imagination, au design innovant, pour des applications durables. </p>
  
          <p class="mt-2">La seule limite de création avec ce matériau est l’imagination… Il peut être coupé, taillé, détouré, poli, sculpté, gravé, poncé, réparé, moulé, thermoformé, sublimé, collé, les possibilités de conception sont presque illimitées. </p> 
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 mt-8 md:mx-40 mx-3 border-solid rounded border-2 border-gray-200 p-3 bg-gray-100">
      <svg xmlns="http://www.w3.org/2000/svg" class="bg-gray-200 mx-auto rounded-full p-2 h-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>
    <h1 class="text-2xl text-center font-bold mb-8 md:mt-10"> SOLID SURFACE </h1>
    <p class="text-center">
    Inventé dans les années 60 par les scientifiques américains de Dupont à la fin des années 60. Ce matériau ne fit son entrée en Europe qu’il y a une vingtaine d’années et était alors cantonné à des usages professionnels : hôpitaux, hôtellerie… Depuis les années 2000, sa diffusion se fait beaucoup plus large avec la montée en puissance du design et de la décoration d’intérieur.
    </p>
    </div>
    <div class="grid grid-cols-1 flex items-center md:grid-cols-3 mt-8 md:mx-40 mx-3">
      <div>
         <div class="hrl"></div>
      </div>
      <div class="">
         <h1 class="text-center text-xl font-bold py-3">Composition du Solid Surface </h1>
      </div>
      <div>
        <div class="hrr"></div>
      </div>
    </div>
    <p class="md:mx-40 mx-3 mt-6 text-center">Solid surface est un matériau de revêtement homogène, massif et non poreux, composé de 1/3 de résine acrylique (également connue sous le nom de Polyméthyle Méthacrylate ou PMMA), et de 2/3 de minéraux naturels.</p>
    <div class="grid grid-cols-1 md:grid-cols-3 mt-8 md:mx-40 mx-3">
      <div class="flex items-top"><span class="inline-block mr-2">&#9675;</span> 
          <p> Pierre (Force / Dureté, Résistance à la chaleur, Résistance aux impacts, Tradition, Fiabilité, Naturel, Noble ).</p>
      </div>
      <div class="flex items-top"><span class="inline-block mr-2">&#9675;</span> <p>Acrylique ( Douceur, Chaleur, Souple,   Profondeur, Flexible, Design, Non poreux, Sans joints, Apparents, Modernité, Créativité).</p>
       </div>
      <div class="flex items-top"><span class="inline-block mr-2">&#9675;</span> <p>Pigments (Palette de couleurs, Créativité).</p>
       </div>
    </div>
    <div class="grid grid-cols-1 flex items-center md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
      <div>
         <div class="hrl"></div>
      </div>
      <div class="">
         <h1 class="text-center text-xl font-bold py-3">Propriétés & atouts du Solid Surface </h1>
      </div>
      <div>
        <div class="hrr"></div>
      </div>
    </div>
    <p class="md:mx-40 text-center pt-3">Le solid surface est la plus ancienne famille de matériaux utilisés dans la réalisation des plans de travail. Matériau non poreux et qui résiste à la plupart des chocs et des rayures, le solid surface est complètement lisse, ce qui empêche les incrustations de bactéries ou de moisissure. Du point de vue esthétique, le solid surface possède l’avantage de se décliner dans de nombreux coloris et de pouvoir prendre la forme que l’on veut. Complètement modulable, ce matériau se travaille en effet aussi bien que le bois : il se coupe, se taille, se sculpte et s’assemble par collages sans joints visibles et se cintre par thermoformage.</p>
    <div class=" w-full  py-14 m-auto mb-12 bg-fixed bg-center bg-cover solid-surface-parallax">
    <div class="grid grid-cols-1 md:grid-cols-4 md:mt-10 mt-8 md:mx-40 mx-3">
       <div class="ss-front-back flex items-center">
          <ul>
            <li>
                <div class="front">
                  <p class="icon"><img src={hamer} alt="résistant" /></p>
                  <p class="title">RÉSISTANT</p>
                </div>
                <div class="back">Solid Surface s’est révélé être un matériau remarquablement durable. Il résiste à la plupart des chocs, des entailles et des coupures qui arrivent dans les lieux de trafic intense.</div>
            </li>
            </ul>
       </div>
       <div class="ss-front-back flex items-center">
          <ul>
            <li>
                <div class="front">
                <p class="icon"><img src={calendar} alt="durable" /></p>
                <p class="title">DURABLE</p></div>
                <div class="back">Le Solid Surface vous assure de nombreuses années de bons et loyaux services. Il vous suffit de suivre les consignes simples énoncées dans notre site pour que vos surfaces conservent toute leur beauté.</div>
            </li>
            </ul>
       </div>
       <div class="ss-front-back flex items-center">
          <ul>
            <li>
                <div class="front"><p class="icon"><img src={health} alt="réparable" /></p>
                <p class="title">RÉPARABLES</p></div>
                <div class="back">Les surfaces en Solid Surface se rénovent : elles retrouvent facilement l'état du neuf avec des nettoyants abrasifs et un tampon à récurer.</div>
            </li>
            </ul>
       </div>
       <div class="ss-front-back flex items-center">
          <ul>
            <li>
                <div class="front"><p class="icon"><img src={joint} alt="JOINTS INVISIBLES" /></p>
                <p class="title">JOINTS INVISIBLES</p></div>
                <div class="back">Les pièces en Solid Surface peuvent être assemblées par collage, pour créer un aspect sans joints apparents, offrant ainsi des possibilités de création illimitées.</div>
            </li>
            </ul>
       </div>
     </div>
     <div class="grid grid-cols-1 md:grid-cols-4 md:mt-10 mt-8 md:mx-40 mx-3">
     <div class="ss-front-back flex items-center">
          <ul>
            <li>
                <div class="front"><p class="icon"><img src={umbrella} alt="Non poreux" /></p>
                <p class="title">NON POREUX</p></div>
                <div class="back">Solid Surface est non poreux et hygiénique. Sa surface ne laisse pas pénétrer les liquides ce qui la rend facile à nettoyer.</div>
            </li>
            </ul>
       </div>
       <div class="ss-front-back flex items-center">
          <ul>
            <li>
                <div class="front"><p class="icon"><img src={drop} alt="hygiènique" /></p>
                <p class="title">HYGIÉNIQUE</p></div>
                <div class="back">Les surfaces en Solid Surface ne permettent pas le développement des bactéries et des moisissures. Solid Surface a été certifié par un laboratoire indépendant comme un matériau hygiénique selon la norme internationale DIN EN ISO 846.</div>
            </li>
            </ul>
       </div>
       <div class="ss-front-back flex items-center">
          <ul>
            <li>
                <div class="front"><p class="icon"><img src={sun} alt="translucide" /></p>
                <p class="title">TRANSLUCIDE</p></div>
                <div class="back">Le Solid Surface est translucide dans certaines couleurs et épaisseurs, il révèle tout son rayonnement et sa profondeur lorsqu'il est exposé à la lumière.</div>
            </li>
            </ul>
       </div>
       <div class="ss-front-back flex items-center">
          <ul>
            <li>
                <div class="front"><p class="icon"><img src={leaf} alt="esthétique" /></p>
                <p class="title">ESTHÉTIQUE</p></div>
                <div class="back">Définitivement esthétique et novateur, le Solid Surface s’affiche comme un matériau technique permettant une décoration lisse et épurée, pour une ambiance simple et élégante.</div>
            </li>
            </ul>
       </div>
    </div>
  </div> 
  <div class="grid grid-cols-1 flex items-center md:mt-10 mt-8 md:mx-40 mx-3">
  <h1 class="text-center text-xl font-bold py-3">LES DIFFÉRENTS TRAITEMENTS DU SOLID SURFACE
 </h1>
 <div class="divider"></div>
 <p class="mt-2">Corsaf Design vous propose une sélection de presses spéciales pour le traitement du Solid Surface, le matériau composite avancé plus en plus apprécié par les professionnels et les designers Depuis utilisés dans de nombreuses applications résidentielles et commerciales.</p>
  </div>
  <div class="grid grid-cols-1 flex items-center md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
      <div>
         <div class="hrl"></div>
      </div>
      <div class="">
         <h1 class="text-center text-xl font-bold py-3">Styles de Bord </h1>
      </div>
      <div>
        <div class="hrr"></div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-3 text-center md:grid-cols-2 md:mt-10 mt-8 md:mx-40 mx-3">
      <div>
        <p>Bords des plans & tables</p>
        <div class="grid grid-cols-3 gap-10">
           { data.g1.edges.map(({ node, index }) => 
                <Img fluid={node.childImageSharp.thumb} /> 
           )} 
        </div>
      </div>
      <div>
        <p>Bords de mur</p>
        <div class="grid grid-cols-2 gap-10">
           { data.g2.edges.map(({ node, index }) => 
                <Img fluid={node.childImageSharp.thumb} /> 
           )} 
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 flex items-center md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
      <div>
         <div class="hrl"></div>
      </div>
      <div class="">
         <h1 class="text-center text-xl font-bold py-3">Les épaisseurs de Panneaux</h1>
      </div>
      <div>
        <div class="hrr"></div>
      </div>
    </div>
    <div class="grid grid-cols-3 flex items-center gap-5 md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
        
           { data.g3.edges.map(({ node, index }) => 
                <Img fluid={node.childImageSharp.thumb} /> 
           )} 
    </div>
    <div class="grid grid-cols-1 flex items-center md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
      <div>
         <div class="hrl"></div>
      </div>
      <div class="">
         <h1 class="text-center text-xl font-bold py-3">Gravure dans le Solid Surface</h1>
      </div>
      <div>
        <div class="hrr"></div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:mt-10 mt-8 md:mx-40 mx-3">
    <p class="text-center">La gravure dans le Solid Surface permet la réalisation de motifs au choix, et d’ajouter une composante design, esthétique à votre mobilier.</p>
      <div class="grid grid-cols-6 gap-3">
           { data.g4.edges.map(({ node, index }) => 
                <Img fluid={node.childImageSharp.thumb} /> 
           )} 
      </div>
    </div>
    <div class="grid grid-cols-1 flex items-center md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
      <div>
         <div class="hrl"></div>
      </div>
      <div class="">
         <h1 class="text-center text-xl font-bold py-3">Thermoformage du Solid Surface</h1>
      </div>
      <div>
        <div class="hrr"></div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:mt-10 mt-8 md:mx-40 mx-3">
       <p >Le thermoformage du Solid Surface permet de donner à la résine minérale des formes et courbes diverses, et de créer du mobilier, Objet Design ou des habillages muraux.</p>
       <div class="gl">
         <Gallery images={g} lightboxOptions={lightboxOptions} />
       </div>
    </div>
    <div class="grid grid-cols-1 flex items-center md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
      <div>
         <div class="hrl"></div>
      </div>
      <div class="">
         <h1 class="text-center text-xl font-bold py-3">Techniques de transformation du Solid Surface</h1>
      </div>
      <div>
        <div class="hrr"></div>
      </div>
    </div>
    <p class="text-center">La transformation du Solid Surface se passe dans cinq techniques principales : </p>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:mt-10 mt-8 md:mx-40 mx-3">
     <div>
       <p class="font-bold mb-2 text-blue-800 underline">Découpage</p>
       <p class="my-0 text-sm">Le Solid Surface est plus dur que le bois. Pour les différents découpage de panneaux entiers il faut utiliser des outils suffisamment puissants pour garantir un bon façonnage du matériau.</p>
       <p class="font-bold text-blue-800 mt-5 mb-2 underline">Thermoformage</p>
       <p class="my-0 text-sm mb-3">Le Solid Surface peut être thermoformé, sous température contrôlée, dans des moules en bois. Le matériau doit d’abord être chauffé à une température comprise entre 160 °C et 175 °C. Il peut être cintré jusqu’à un rayon interne d’au maximum 25 mm, selon l’épaisseur et le coloris du panneau. Un refroidissement graduel dans le moule permet d’éviter d’éventuelles déformations induites par les tensions résiduelles et donne de meilleurs résultats.</p>
       <div class="thermo-gallery px-3 gl"><Gallery images={thermog1} /></div>
     </div>
     <div>
        <div class="thermo-gallery px-3 gl"><Gallery images={thermog2} /></div>
        <p class="font-bold mb-2 text-blue-800 underline mt-3">Collage</p>
        <p class="my-0 text-sm">Tous les bords doivent être droits, équarris et minutieusement nettoyés avec un tissu doux, propre et blanc, imprégné d’alcool dénaturé, avant l’application de la colle. Pour réaliser des joints imperceptibles – une des caractéristiques intéressantes de Solid Surface –, on utilise la colle Corian à deux composants de même coloris que le panneau.</p>

        <p class="font-bold text-blue-800 mt-5 mb-2 underline">Ponçage</p>
        <p class="my-0 text-sm">Pour le ponçage des surfaces, il est recommandé d’utiliser une ponceuse excentrique, avec laquelle on effectue des petits mouvements circulaires dans deux sens (nord-sud, est-ouest). Le ponçage se fait en trois ou quatre passages avec du papier de verre au grain de plus en plus </p>fin.
     </div>
    </div>
    <div class=" w-full  justify-center py-1 m-auto mb-12 bg-fixed bg-center bg-cover ss-parallax"> 
           <div class="grid grid-cols-1 md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
                <div class="hrl"></div>
                <h1 class="text-center text-xl font-bold py-3">Comment entretenir le Solid Surface?</h1>
                <div class="hrr"></div>
           </div> 
           <div class="p-5 rounded-xl  flex items-center">
           <div class="grid grid-cols-3 gap-5 md:mx-40 text-sm">
             <div>
                <p>
                  <span class="font-bold">Solid Surface</span> vous assure de nombreuses années de bons et loyaux services. Il vous suffit de suivre les consignes simples citer dans cet article pour que vos surfaces conservent toute leur beauté.</p>
    
                  <p class="text-blue-800 font-bold mt-5 mb-3">Pour les plans de cuisine et les éviers</p>
    
                  Pour préserver l’apparence de ce produit, nous vous recommandons de le nettoyer quotidiennement, en utilisant un torchon humide en microfibre et un produit d’entretien standard pour cuisine sont suffisants pour le nettoyage.
    
                  <p class="text-blue-800 font-bold mt-5 mb-3">Ce à quoi vous devez faire attention :</p>
    
                  <p class="text-gray-800 my-3 font-bold">La chaleur :</p>
                 <p>
                  Utilisez toujours un tapis isolant, un dessous-de-plat (à pieds en caoutchouc) ou un tapis d’évier, pour poser une casserole brûlante.
                </p>
             </div>

              <div>
               <p class="m"> Ne posez jamais de casserole brûlante, en particulier en fonte, directement sur un plan de travail ou dans un évier Solid Surface. De telles températures peuvent endommager n’importe quel type de surface.</p>
  
                <p class="my-4 font-bold text-gray-800">Les rayures :</p>
  
                <p class="my-2">Comme tous les matériaux, Solid Surface finit par se rayer légèrement au cours d’une utilisation quotidienne normale.</p>
  
                <p class="my-2">Ne coupez rien directement sur une surface Solid Surface : la lame entamerait le plan de travail. Servez-vous d’une planche à découper.</p>
  
                <p class="my-2">Les rayures, la poussière et l’usure normale sont plus visibles sur les couleurs sombres très pigmentées que sur les teintes plus claires et texturées (pour plus de détails, référez-vous à notre brochure sur le choix des couleurs).</p>
              </div>

              <div>
               <p class="mb-4 font-bold text-gray-800"> Les produits chimiques :</p>
  
                <p class="my-2">Une éclaboussure accidentelle d’un produit chimique puissant (décapant à peinture, nettoyant à pinceau, à métaux et à four, produits nettoyants contenant du chlorure de méthylène, produits de débouchage à l’acide, dissolvant à base d’acétone pour vernis à ongles, etc.) doit être rincée immédiatement et abondamment avec un mélange d’eau et de savon afin de ne pas endommager le plan de travail ! En cas de tache de vernis, utilisez un dissolvant sans acétone, puis rincez à l’eau. L’exposition non détectée ou prolongée à des produits chimiques risque d’endommager la surface.</p>
  
                <p class="my-4 font-bold text-gray-800">Robinets d’eau bouillante :</p>
  
                <p class="">Un récipient doit toujours être utilisé pour recueillir l’eau des robinets d’eau bouillante, qui peut endommager la surface de l’évier en Solid Surface. Ces dommages ne sont pas pris en compte par la garantie Corsaf Design.</p>
              </div>
           </div>
          </div>
          <div class="md:mx-40"><p class="font-bold text-center">Quoiqu’il arrive, ne désespérez pas !</p>

          <p class="text-center">Solid Surface est un matériau regénérable car il est massif et homogène sur toute son épaisseur. La plupart des dégâts, y compris les dommages importants dus à un choc, à la chaleur ou à des produits chimiques, sont habituellement réparables sur place pour redonner aux surfaces leur fini et leur hygiène d’origine.</p>
        </div>
    </div> 
    <div class="grid grid-cols-1 flex items-center md:grid-cols-3 md:mt-10 mt-8 md:mx-40 mx-3">
      <div>
         <div class="hrl"></div>
      </div>
      <div class="">
         <h1 class="text-center text-xl font-bold py-3">PRINCIPAUX FOURNISSEURS</h1>
      </div>
      <div>
        <div class="hrr"></div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 content-between md:mt-10 mt-8 md:mx-40 mx-3">
       <a href="https://www.stoneitaliana.com/en" target="top" ><img src={stoneItaliana} class="rounded-full mx-auto" alt="fournisseur Stone Italiana"/></a>
       <a href="http://www.birlesikakrilik.com/_en/index.html" target="top" ><img src={basSurface} class="rounded-full mx-auto" alt="fournisseur Bas Surface" /></a>
       <a href="https://www.rehau.com/fr-fr/micropages/portail-amenagement-interieur/surfaces/minerales/les-coloris" target="top" ><img src={rehau} class="rounded-full mx-auto" alt="fournisseur REHAU" /></a>
    </div>
  </Layout>
)
}
export const query = graphql`
query SSQuery {
  g:allFile (filter:{relativeDirectory: {eq: "img/solid-surface/thermo"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  g1:allFile (filter:{relativeDirectory: {eq: "img/solid-surface/table"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          } 
        }
      }
    }
  }
  g2:allFile (filter:{relativeDirectory: {eq: "img/solid-surface/mur"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          } 
        }
      }
    }
  }
  g3:allFile (filter:{relativeDirectory: {eq: "img/solid-surface"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          } 
        }
      }
    }
  }
  g4:allFile (filter:{relativeDirectory: {eq: "img/solid-surface/gravure"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          } 
        }
      }
    }
  }
  Thermog1:allFile (filter:{relativeDirectory: {eq: "img/solid-surface/thermo/g1"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  Thermog2:allFile (filter:{relativeDirectory: {eq: "img/solid-surface/thermo/g2"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default SolidSurfacePage
